import React from "react";
import cbCommingSoon from "../src/assets/CraftsBuy Coming Soon Web.png";
import cbCommingSoonMob from "../src/assets/CraftsBuy Coming Soon Mobile.png"
import "./App.css";

const App = () => {
  return (
    <div className="container">
      <img src={cbCommingSoon} alt="" className="imageWeb" />
      <img src={cbCommingSoonMob} alt="" className="imageMob" />
    </div>
  );
};

export default App;
